import React, { Component } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import {
  AuthForm,
  FormInputLoginContainer,
  StyledLabel,
  SubmitButton,
  BackButton
} from '../../StylesPages/loginStyle'
import { TitleCard } from '../../StylesPages/membershipStyle'
import { InputContainer, StyledInput, StyledError, CustomButton } from '../../Collapse/stepped/style'
import { ButtonContainer } from '../../Button/style'

class ForgotPasswordSection extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      error: null,
      email: ``,
      showForm: true
    }
    this.showForgotPassForm.bind(this)
  }

  notify = (message: string, type?: string) => {
    if (type === 'error') {
      return toast.error(message)
    }
    return toast(message)
  }

  handleUpdate = (event: any) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleValidation = () => {
    let formIsValid = true
    if (!this.state.email) {
      formIsValid = false
      this.setState({ error: 'El correo electrónico es requerido' })
    }
    return formIsValid
  }

  sendLink = () => {
    axios
      .post(`${process.env.API_URL}customer/password-recovery`, { email: this.state.email }, {})
      .then(response => response.data)
      .then((passwordResponse: any) => {
        if (passwordResponse.mailSent === true) {
          this.setState({
            showForm: false
          })
          return
        }
        if (passwordResponse.message === 'user not found') {
          this.notify('Usuario no encontrado', 'error')
        }
      })
      .catch(error => {
        throw error
      })
  }

  showForgotPassForm = () => (
    <AuthForm
      display={this.state.showForm}
      method="post"
      onSubmit={(event: any) => {
        event.preventDefault()
        if (this.handleValidation()) {
          this.sendLink()
        }
      }}
    >
      <BackButton type="button" onClick={() => navigate('/login')}>
        <i className="icon-grande-table-13 Fill-1" />
        Login
      </BackButton>
      <TitleCard className="center-title">Recuperar contraseña</TitleCard>
      <FormInputLoginContainer>
        <StyledLabel>Correo electrónico registrado</StyledLabel>
        <InputContainer>
          <StyledInput
            placeholder="Ingresa tu correo electrónico"
            error={this.state.error}
            name="email"
            onChange={this.handleUpdate}
          />
          {this.state.error && <StyledError>{this.state.error}</StyledError>}
        </InputContainer>
      </FormInputLoginContainer>
      <StyledLabel className="padding-bottom">
        Te enviaremos un link a tu correo electrónico
      </StyledLabel>
      <ButtonContainer>
        <SubmitButton type="submit" value="Recuperar" />
      </ButtonContainer>
      <ButtonContainer className="secondary-btn-container">
        <CustomButton id="cancel-btn" onClick={() => navigate('/')}>
          Cancelar
        </CustomButton>
      </ButtonContainer>
    </AuthForm>
  )

  showPasswordResetMessageForm = () => (
    <AuthForm display={!this.state.showForm}>
      <TitleCard className="center-title">Restablecimiento de contraseña</TitleCard>
      <StyledLabel className="padding-bottom">
        Te hemos enviado un correo electrónico con instrucciones para volver a establecer tu
        contraseña.
      </StyledLabel>
    </AuthForm>
  )

  render() {
    return (
      <div>
        {this.showForgotPassForm()}
        {this.showPasswordResetMessageForm()}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          draggable={true}
          pauseOnHover
        />
      </div>
    )
  }
}

export default ForgotPasswordSection
